import { apiClient } from "./ApiClient";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function getRentalById(id) {
    return apiClient.get(`${API_BASE_URL}/api/rentals/${id}`)
}

export function getRentalsWidget() {
    return apiClient.get(`${API_BASE_URL}/api/rentals?numberOfRentals=3`);
}

export function getRentalsWidgetByLocation(location) {
    return apiClient.get(`${API_BASE_URL}/api/rentals/location/top3/${location}`);
}

export function getRentalsCarousel(numberOfRentals) {
    let params = new URLSearchParams({
        numberOfRentals:numberOfRentals,
    });    
    return apiClient.get(`${API_BASE_URL}/api/rentals?${params}`);
}

export function getRentalsPropertiesPage() {
    // let params = new URLSearchParams({
    //     numberOfRentals:numberOfRentals,
    // });  
    return apiClient.get(`${API_BASE_URL}/api/rentals`);
}

export function getRentalsByType(propertyType) {
    return apiClient.get(`${API_BASE_URL}/api/rentals/${propertyType}`);
}

export function getBuildings(propertyType,buildingId) {
    let params = new URLSearchParams({
        propertyType:propertyType,
        buildingId:buildingId,
    });  
    return apiClient.get(`${API_BASE_URL}/api/buildings?${params}`);
}


export function getRentalsByLocation(location) {
    return apiClient.get(`${API_BASE_URL}/api/rentals/location/${location}`);
}

export function getRentalTypeCount() {
    return apiClient.get(`${API_BASE_URL}/api/rentals/countByType`);
}

export function getRentalRegionCount() {
    return apiClient.get(`${API_BASE_URL}/api/rentals/countByRegion`);
}