import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Import Link
import backgroundImage from "../assets/images/BrowenHome.jpg";

const NewHome = () => {
	return (
		<Container>
			<Section>
				<StyledLink to="/about"> {/* Link to the About Us page */}
					<SectionText>About Us</SectionText>
				</StyledLink>
			</Section>
			<Divider />
			<Section>
				<StyledLink to="/properties"> {/* Link to the Commercial page */}
					<SectionText>Services</SectionText>
				</StyledLink>
			</Section>
			<Divider />
			<Section>
				<StyledLink to="/properties"> {/* Link to the Residential page */}
					<SectionText>Properties</SectionText>
				</StyledLink>
			</Section>
			<Divider />
			<Section>
				<StyledLink to="/contact"> {/* Link to the Contact Us page */}
					<SectionText>Contact Us</SectionText>
				</StyledLink>
			</Section>
		</Container>
	);
};

export default NewHome;

const Container = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	background-image: url(${backgroundImage});
	background-size: cover;
	background-position: center;
	background-attachment: fixed;

	@media (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

const Section = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 50, 0.15);
	margin: 0 2%; /* Leaves 10% space on the sides (5% on each side) */
	transition: background-color 0.3s ease, transform 0.3s ease;

	&:hover {
		background-color: rgba(0, 0, 50, 0.5);
	}

	@media (max-width: 768px) {
		margin: 17% 0; /* Adjusts margin for top and bottom when stacked */
		width: 90%; /* Reduces the width of the sections when stacked */
	}
`;

const SectionText = styled.h1`
	color: white;
	font-size: 2rem;
	transition: transform 0.3s ease;
	cursor: pointer;

	&:hover {
		transform: scale(1.2);
	}
`;

const Divider = styled.div`
	width: 2px;
	background-color: white;
	height: 96%; /* Leaves 2% space at the top and bottom */
	align-self: center;

	@media (max-width: 768px) {
		width: 90%; /* Full width for a horizontal line when stacked */
		height: 2px; /* Changes to a horizontal line */
		margin: 2% 0; /* Adds margin for spacing between sections */
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none; /* Remove underline from links */
	color: inherit; /* Inherit the text color */
`;
