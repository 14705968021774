import styled from 'styled-components';
import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../security/CurrentUserContext.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function RegisterComponent() {

    const navigate = useNavigate();

    const {
        currentUserState, 
        actions: { 
            receiveUserInfoFromServer, 
            receiveUserAuthFromServer,
            removeCurrentUser 
        }} = useContext(CurrentUserContext);  
    
    const [Firstname, setFirstname] = useState('');
    const [Lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dateNow, setDateNow] = useState('');

    const [changepassclicked, setChangepassclicked] = useState(false);
    
    const [confirmNewUser, setconfirmNewUser] = useState(false)

    useEffect(() => {
        const now = new Date();
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        const formattedString = now.toLocaleString('en-US', options);
        setDateNow(formattedString);
    }, []); 
    
    const handleSubmit = async (event) => {
        event.preventDefault(); 
    
        // Construct the createUserBody object to include formData
        const createUserBody = {
            formData: {
                first_name: Firstname,
                last_name: Lastname,
                email: email,
                phone: phone,
                password: password,
            }
        };
    
        // Use fetch to send the POST request
        fetch(`${API_BASE_URL}/api/createuser`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createUserBody),
            credentials: 'include',
        })
        .then(response => {
            if (response.ok) {
                return response.json(); // Process the response if it's successful
            } else {
                throw new Error('Failed to create user'); // Handle errors
            }
        })
        .then(responseJson => {
            console.log(responseJson); // Log the response from the server
            setconfirmNewUser(true); // Assuming you want to confirm the user was added
    
            // Reset the form fields
            setFirstname("");
            setLastname("");
            setEmail("");
            setPhone("");
            setPassword("");
        })
        .catch(error => console.error('Error:', error));
    };

   
    
    return (
        <>
            <section className="w3l-form-2">
                <div className="">
                    <div className="header">
                        <a href="index.html" className="brand-logo">BROWEN</a>
                        <a href="login" className="signin">Login</a>
                    </div>

                    <div className="wrapper">
                        <div className="text-main">
                            <div className="main1">
                                <div className="tagline">
                                    <p className="form-tagline">Register your account here</p>
                                    <h1 className="head-form">The best way to find your home villas</h1>
                                    <p className="form-para1">Integer blandit eget dui in tempor. Pellentesque gravida tortor at eros,
                                        et ullamcorper magna blandit. In id metus id tellus vestibulum feugiat. Aene an et tellus tincidunt, pretium
                                        libero vel, condimentum leo. Sed leo lacus, consectetur vitae fringilla eu, ornare eu augue. Aliquam in
                                        dui, nec mattis velit. Mauris at mauris erat.  </p>
                                </div>
                            </div>
                            <div className="main2">
                                <div className="sign-main">
                                    <p className="signup">Create your account</p>
                                    <p className="create">Register you account by giving required details</p>
                                    <form onSubmit={handleSubmit} className="sign-up-form">
                                        <input 
                                            type="text" 
                                            className="form-type" 
                                            placeholder="First Name" 
                                            required 
                                            value={Firstname}
                                            onChange={(event) => setFirstname(event.target.value)}
                                        />
                                        <input 
                                            type="text" 
                                            className="form-type" 
                                            placeholder="Last Name" 
                                            required 
                                            value={Lastname}
                                            onChange={(event) => setLastname(event.target.value)}
                                        />
                                        <input
                                            type="email"
                                            className="form-type"
                                            placeholder="Your email"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            required 
                                        />
                                        <input 
                                            type="text" 
                                            className="form-type" 
                                            placeholder="Your phone number" 
                                            value={phone}
                                            onChange={(event) => setPhone(event.target.value)}
                                            required 
                                        />
                                        <input 
                                            type="password"
                                            className="form-type" 
                                            placeholder="Your password" 
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                            required 
                                        />
                                        
                                        <div>
                                            <label className="check-remember container">
                                                <input type="checkbox" className="form-check" /> <span className="checkmark"></span>
                                                <p className="privacy-policy">I agree to the terms of use and privacy policy</p>
                                            </label>
                                            <div className="clear"></div>
                                        </div>
                                        <button type="submit" className="create-account">Get started</button>
                                    </form>
                                    <p className="account text-center">Already have an account?  Go to <a href="login.html" className="login">Login</a> </p>
                                    
                                    {confirmNewUser && (
                                        <CreatedSuccesMessage>
                                            Successfully created new user{' '}
                                        </CreatedSuccesMessage>
                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const CreatedSuccesMessage = styled.div`
    color: green;
    font-weight: bold;
    font-size: 42px;
`;
