import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { useState, useRef } from 'react';

const DateInput = ({
    selectedDate,
    handleInputChangeEdit,
    itemId,
    field,
    disabled
}) => {
    let dateFormat = new Date();
    if (selectedDate) {
        // timestamp for DatePicker format for regular input 
        let timeStamp = Date.parse(selectedDate);
        dateFormat = new Date(timeStamp);
        // console.log("dateFormat",dateFormat)

        // YYYY-MM-DD format for regular input 
        // const timeStamp = Date.parse(selectedDate);
        // dateFormat = new Date(timeStamp);
        // dateFormat = `${dateFormat.getFullYear()}-${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}-${dateFormat.getDate().toString().padStart(2, '0')}`;
        
    } else {
        dateFormat = dateFormat;
    }
 
    const handleChooseDate = (event) => {
        
        const tempTimeStamp = Date.parse(event);
        const tempDateFormat = new Date(tempTimeStamp);
        const tempFormattedDate= tempDateFormat.toLocaleDateString('en-US', { month: 'long', day: 'numeric',year: 'numeric'});
        // console.log("formattedDate",formattedDate)
        handleInputChangeEdit(tempFormattedDate)
    };
    return (
        <StyledDateInput>
            {/* <div onClick={(event) => handleInputClick(event)} style={{ display: 'inline-block' }}> */}
            {/* <InputValue
                ref={inputRef}
                type="date"
                value={dateFormat}
                disabled={disabled}
                onChange={(event) => handleInputChangeEdit(event, itemId, "available_from", event.target.value)}                
            /> */}
            {/* </div> */}
            <Input
                id="date-input"
                selected={dateFormat}
                onChange = {(event)=>{handleChooseDate(event)}}
                dateFormat="MMMM d, yyyy"
                placeholderText="Click to select a date"
                disabled={disabled}
            />
        </StyledDateInput>
    );
};

export default DateInput;

const InputValue = styled.input`
width: 100px;
flex: 1;
margin-right: 10px;
// border:none;
font-size: 14px;
cursor: pointer;

// -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;

//   /* Hide the default calendar icon */
//   &::-webkit-calendar-picker-indicator {
//     display: none;
//   }

`;

const StyledDateInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
    }
`;

const Input = styled(DatePicker)`
    // margin-right: 10px;
    white-space: nowrap;
    // font-family: var(--heading-font-family);
    font-size: 14px;
    // height: 42px;
    // width: 100%;
    height:21px;
    width: 100px;
    // cursor: default;
    border-bottom: 2px solid grey; 
    border-right: 2px solid grey;
    margin-right: 10px;

    &:hover {
    cursor: pointer;
    }
     &:disabled {
        cursor: default;  // Cursor as default when disabled
        opacity: 0.5;     // Optional: Reduce opacity when disabled for visual feedback
    }
`;
