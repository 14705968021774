import './App.css';
import { Routes, Route, useLocation, Navigate} from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import HomeComponent from './components/HomeComponent';
import PricingComponent from './components/PricingComponent';
import MoveTopComponent from './components/MoveTopComponent';
import LoginComponent from './components/LoginComponent';
import RegisterComponent from './components/RegisterComponent';
import ErrorComponent from './components/ErrorComponent';
import AboutComponent from './components/AboutComponent';
import ServicesComponent from './components/ServicesComponent';
import AdminComponent from './components/Admin';
import ContactComponent from './components/ContactComponenet';
import PropertyByTypeComponent from './components/PropertyByTypeComponent';
import PropertyByLocationComponent from './components/PropertyByLocationComponent';
import PropertyDetailComponent from './components/PropertyDetailComponent';
import { CurrentUserContext } from "./security/CurrentUserContext";
import NewHome from './components/NewHome';
import Door from './components/DoorComp';
import Cookies from 'js-cookie';
import ProspectingMap from './components/ProspectingMap';

function App() {

	let location = useLocation();
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser,
        },
    } = useContext(CurrentUserContext);

	// receiveUserAuthFromServer({ authenticated: true });
	useEffect(() => {
        let params = new URLSearchParams({
            sessionid: currentUserState._id.toString(),
        });
        // if page is refreshed, CurrentUserContext is lost, so we send blank sessionsid in params,
        // to tell the server to retreive the information.
        
        fetch(`${API_BASE_URL}/api/validateauth`, {
            credentials: "include",
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Unauthorized");
                }
            })
            .then((response_j) => {
                receiveUserInfoFromServer(response_j.data);
                receiveUserAuthFromServer({ authenticated: true });
            })
            .catch((err) => console.log(err));
    }, []);

	return (
		<>	
			{location.pathname !== '/login' && location.pathname !== '/register' && !location.pathname.startsWith('/admin') && <HeaderComponent />}
				<Routes>
					{/* <Route path='/' element={<AuthenticatedRoute><HomeComponent /></AuthenticatedRoute>}></Route> */}
					{/* <Route path="/" element={<HomeComponent />}></Route> */}
                    <Route path="/" element={<NewHome />}></Route>
                    
					{/* <Route path="/pricing" element={<AuthenticatedRoute><PricingComponent /></AuthenticatedRoute>}></Route> */}
					<Route path="/login" element={<LoginComponent />}></Route>
					<Route path="/register" element={<RegisterComponent />}></Route>
					<Route path="/about" element={<AboutComponent />}></Route>
					<Route path="/services" element={<ServicesComponent />}></Route>
					<Route path="/contact" element={<ContactComponent />}></Route>
					<Route path="/properties" element={<PropertyByTypeComponent />}></Route>
					<Route path="/properties/location/:location" element={<PropertyByLocationComponent />}></Route>
					<Route path="/properties/:id" element={<PropertyDetailComponent />}></Route>
					
                    <Route path="/admin/:select_building" element={<AdminComponent />}></Route> {/*  more specific route before more general */}
                    <Route path="/admin" exact element={<AdminComponent />}></Route>
                    <Route path="/admin/door" exact element={<Door />}></Route>

                    <Route path="/admin/prospectingmap" exact element={<ProspectingMap />}></Route>
                    <Route path="/admin/prospectingmap/:select_list" exact element={<ProspectingMap />}></Route>

                    <Route path="/admin/prospectingmap/:select_list/:unit_id" exact element={<ProspectingMap />}></Route>

                    {/* <Route path="/admin/instantmessage" element={<Messaging/>}></Route> */}
                    {/* <Route path="/newhome" exact element={<NewHome />}></Route> */}

                    <Route path="/*" element={<ErrorComponent />}></Route>
                    
				</Routes>
			{location.pathname !== '/login' && location.pathname !== '/register' && !location.pathname.startsWith('/admin') && <FooterComponent />}
			<MoveTopComponent />
		</>
	);
}

export default App;
