import React, { useState , useEffect} from 'react';
import styled from 'styled-components';

const DropdownMenu = ({ options, onSelect , value, isDisabled}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectOption = (event, option) => {
        event.preventDefault();
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(event, option.value);
    };

    const handleDrownDownButton = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (value===true){
            setSelectedOption(options[0])
        }else{
            setSelectedOption(options[1])
        }
    }, []);

    useEffect(() => {
        if (isDisabled){
            setIsOpen(false);
        }
    }, [isDisabled]);
    

    return (
        <DropdownContainer>
            <DropdownButton disabled={isDisabled} onClick={(event) => handleDrownDownButton(event)}>
                {selectedOption ? selectedOption.label : 'Select an option'}
            </DropdownButton>
            {isOpen && (
                <DropdownList>
                    {options.map((option) => (
                        <DropdownItem
                            key={option.value}
                            onClick={(event) =>
                                handleSelectOption(event, option)
                            }
                        >
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </DropdownContainer>
    );
};

export default DropdownMenu;

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    
`;

const DropdownButton = styled.button`
    /* border-radius: 1px; */
    height:21px;
    border-bottom: 2px solid grey; 
    border-right: 2px solid grey;
    background-color: white;
    color: black;
    /* padding: 8px; */
    font-size: 14px;
    /* border: none; */
    min-width: 100px;
    cursor: pointer;
    &:disabled {
        background-color: #f0f0f0;  // Example gray color to indicate disabled state
        // color: #c0c0c0;             // Dim the text color     
        cursor: default;  // Cursor as default when disabled
        opacity: 0.5;     // Optional: Reduce opacity when disabled for visual feedback
    
    }
`;

const DropdownList = styled.ul`
    border-radius: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    list-style: none;
    background-color: white;
    min-width: 100px;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    
`;

const DropdownItem = styled.li`
    padding: 4px 6px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
        background-color: #ddd;
    }
`;
