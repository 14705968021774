import React, { useState } from "react";
import styled from "styled-components";

const SidebarListItem = ({ buildings, onBuildingSelect, selectedList }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the visibility of the buildings list
    const toggleList = () => {
        setIsOpen(!isOpen);
    };
    // buildings = [
    //     'Building A',
    //     'Building B',
    //     'Building C',
    //     'Building D',
    //     'Building E',
    //     'Building F',
    //     'Building G',
    //     'Building H',
    //     'Building I',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     'Building J',
    //     // Add more buildings as needed
    //   ];
    // console.log("selectedList",selectedList)
    return (
        <Container>
            <Button onClick={toggleList}>Lists 
                {isOpen ? (<>&nbsp;(See Less)</>) : (<>&nbsp;(See more)</>) }
                </Button>
            {isOpen && (
                <List>
                    <ListItem
                        key={"all_lists_together"}
                        onClick={() => onBuildingSelect("all_lists_together")}
                        isSelected={selectedList === "all_lists_together"}
                    >
                        All Lists
                    </ListItem>
                    {buildings.map((building, index) => (
                        <ListItem key={building._id} onClick={() => onBuildingSelect(building.listcollection)} isSelected={selectedList === building.listcollection}>
                            {building.list_name}
                        </ListItem>
                    ))}
                </List>
            )}
        </Container>
    );
};

// Styled-components for styling

const Container = styled.div`
    width: 162px;
    margin: 0 auto;
`;

const Button = styled.button`
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 16px;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #f5f5f5;
    &:hover {
        background-color: #d6d6d6;
    }
`;

const List = styled.ul`
    max-height: 600px; /* Adjust this value as needed */
    /* overflow-y: hidden; */
    overflow-y: scroll;
    /* overflow-y: auto; */
    width: 178px;
    padding: 0;
    margin: 0;
    border: none;
    list-style: none;
    /* border: 1px solid #ccc; */
    /* scrollbar-gutter: stable;  */
    padding-right: 5px;
    /* margin-right: ; */
    /* box-sizing: content-box; */

    ::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners */
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Color when hovering over the scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* Background of the scrollbar track */
    }

    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
`;

const ListItem = styled.li`
    padding: 12px 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    color: black;
    background-color: ${(props) => (props.isSelected ? "#c7ecfd" : "#f5f5f5")};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isSelected ? "#9dc6da" : "#d6d6d6")};
    }

    /* &:not(:last-child) {
        border-bottom: 1px solid #eee;
    } */
`;

export default SidebarListItem;
