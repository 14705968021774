import React, { useState , useEffect} from 'react';
import styled from 'styled-components';
import DropdownMenu from '../assets/styledcomponents/DropdownMenu.js';
import DateInput from '../assets/styledcomponents/DateInput.js';

const TableRowComponent = ({ item, editingRowId, handleInputChange, handleSave, handleCancel, handleDeleteUnit, toggleEditing, units, setUnits}) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedOption, setSelectedOption] = useState(null);

    return (
        <Item key={item._id}>
        {/* <Value_id>{count}</Value_id> */}
    
        <Value
            type="text"
            value={item.unit_name}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "unit_name", event.target.value, units, setUnits)}
        />
    
        <Value
            type="text"
            value={item.model_type}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "model_type", event.target.value, units, setUnits)}
        />
    
        <Value
            type="number"
            value={item.floor_number}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "floor_number", event.target.value, units, setUnits)}
        />
        <Value
            type="number"
            value={item.monthly_rent}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "monthly_rent", event.target.value, units, setUnits)}
        />
        <Value
            type="number"
            value={item.square_feet}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "square_feet", event.target.value, units, setUnits)}
        />
    
        <Value
            type="number"
            value={item.number_bedrooms}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "number_bedrooms", event.target.value, units, setUnits)}
        />
    
        <Value
            type="number"
            value={item.number_baths}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "number_baths", event.target.value, units, setUnits)}
        />
        
        <div style={{minWidth:"111px", display: "flex"}}>                                                                
            <DropdownMenu
                options={[{label: "Vacant",value: true},{label: "Occupied",value: false}]}
                onSelect={(event, optionValue) =>handleInputChange(event, item._id, "availability", optionValue, units, setUnits)}
                value={item.availability}
                isDisabled={editingRowId !== item._id}                                                           
            />                                                                
        </div>
                                                                    
        <div style={{minWidth:"100px", display: "flex"}}>        
            <DateInput
                selectedDate={item.available_from}
                // handleInputChangeEdit={handleInputChange}
                handleInputChangeEdit={(optionValue) =>handleInputChange("", item._id, "available_from", optionValue, units, setUnits)}
                itemId={item._id}
                field={'available_from'}
                disabled={editingRowId !==item._id}
            />
        </div>
    
        <Value
            type="text"
            value={item.floorplan_url}
            disabled={editingRowId !== item._id}
            onChange={(event) => handleInputChange(event, item._id, "floorplan_url", event.target.value, units, setUnits)}
        />
    
        <Label>
            {editingRowId === item._id ?
                <>
    
                    <Button type="submit" onClick={(event) => handleSave(event, item._id)}>Save</Button>
                    {/* <Button type="submit" disabled={loadingButton}>
                        {loadingButton ? (<LoadingStyle style={{width: '20px',height: '20px'}}/>) : (<>Save</>)}
                    </Button> */}
    
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={(event) => handleDeleteUnit(event, item._id,item.buildingcollection)}>Delete</Button>
                </>
                :
                <Button onClick={(event) => toggleEditing(event, item._id)}>Edit</Button>
            }
        </Label>
    
    
    </Item>
    );
};

export default TableRowComponent;

const Item = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
background-color: #f5f5f5;
padding: 10px;
border-bottom: 1px solid black;
`;

const Button = styled.button`
font-size:22px;
padding-left: 10px;
padding-right: 10px;
background-color: #ef5350;
color: #fff;
border: none;
border-radius: 5px;
margin-bottom: 1px;
cursor: pointer;
&:hover {
background-color: #e53935;
}
`;

const Label = styled.div`
width: 100px;
font-weight: bold;
flex: 1;
margin-right: 10px;
/* padding: 0 12px; */
font-family: var(--font-body);
min-width: 100px;
`;

const Value = styled.input`
width: 100px;
flex: 1;
margin-right: 10px;
/* border:none; */
`;
