import { useState, useEffect, useContext } from "react";
import { RadioButton, Success, LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";
import styled from "styled-components";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import DropdownPropType from "../assets/styledcomponents/DropdownPropType.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ModalComponentMap = ({ isOpen, onSubmit, setShowModal, setPostSuccess, setSelectedBuilding }) => {
    const [formData, setFormData] = useState({
        list_name: ""
    });

    const [loadingState, setLoadingState] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleDropDown = (event, field, value) => {
        event.preventDefault();
        // console.log("event",event, field, value)
        const newData = { ...formData, [field]: value };
        setFormData(newData);
        // console.log("newData",newData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingState(true);

        // setExcelData({...excelData,list_name:formData.list_name});
        // console.log("excelData",excelData)
        onSubmit(excelData, formData.list_name);
        setFormData({ list_name: "" });
        setExcelData([]);
        setLoadingState(false);
    };

    if (!isOpen) return null;

    const handleFileRead = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                encoding: "UTF-8",
                complete: function (results) {
                    setExcelData(results.data);
                },
                header: true,
                skipEmptyLines: true
            });
        }
    };
    const handleCancel = (event) => {
        event.preventDefault();
        setFormData({ list_name: "" });
        setShowModal(false);
    };

    const handleDownloadTemplate = () => {
        const templateData = [
            [
                "Latitude",
                "Longitude",
                "lot_number",
                "Concat_address",
                "Adresse postale (Owner)",
                "Utilisation prédominante :",
                "Propriétaire :",
                "Aire des étages :",
                "Superficie :",
                "Valeur de l'immeuble :",
                "Année de construction :",
                "Nombre de locaux non résidentiels :",
                "ownername1",
                "owner_address1",
                "phone1",
                "ownername2",
                "owner_address2",
                "phone2",
                "propstatus",
                "notes"
            ] // Replace with exact column headers needed
        ];

        const csv = Papa.unparse(templateData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        link.setAttribute("href", url);
        link.setAttribute("download", "building_template.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modal}>
                <h2 style={{ margin: "10px" }}>Add New List</h2>

                <form onSubmit={handleSubmit}>
                    <GridContainer>
                        {/* <StyledInput placeholder="Input 1" />
                <StyledInput placeholder="Input 2" />
                <StyledInput placeholder="Input 3" />
                <StyledInput placeholder="Input 4" /> */}

                        <label style={{ margin: "5px" }}>
                            List Name:
                            <input type="text" name="list_name" value={formData.list_name} onChange={handleChange} required />
                        </label>

                        {loadingState ? (
                            <LoadingStyle style={{ width: "20px", height: "20px", margin: "5px" }} />
                        ) : (
                            <>
                                {/* <div style={{ display: "flex",  flexDirection: "column"}}> */}
                                <Button type="submit">Add List</Button>
                                <Button type="button" onClick={(event) => handleCancel(event)}>
                                    Cancel
                                </Button>

                                {/* <Button style={{}} type="button" onClick={handleFileRead}>Add Building with CSV</Button> */}
                                <input type="file" accept=".csv" onChange={handleFileRead} style={{ display: "none" }} id="fileInput" />
                                <Button type="button" onClick={() => document.getElementById("fileInput").click()}>
                                    Upload CSV
                                </Button>

                                <Button
                                    style={
                                        {
                                            // whiteSpace:"normal",
                                            // wordWrap:"break-word",
                                            // width: "130px"
                                            // fontSize: 10
                                        }
                                    }
                                    type="button"
                                    onClick={handleDownloadTemplate}
                                >
                                    Download CSV Template
                                </Button>

                                {/* create loading state for this  */}
                            </>
                        )}
                    </GridContainer>
                </form>
            </div>
        </div>
    );
};

// Styled div container with grid layout
const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr); // This line creates 1 columns
    /* grid-template-columns: repeat(2, 1fr); // This line creates 2 columns */
    gap: 10px; // Space between grid items
    padding: 20px; // Padding around the grid container
`;

const GridContainer2 = styled.div`
    display: grid;
    /* grid-template-columns: repeat(1, 1fr); // This line creates 1 columns */
    grid-template-columns: repeat(2, 1fr); // This line creates 2 columns
    gap: 10px; // Space between grid items
    padding: 20px; // Padding around the grid container
`;

// Styled input element
const StyledInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Button = styled.button`
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        background-color: #e53935;
    }
`;

const styles = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000 // Add this line
    },
    modal: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "5px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
    }
};

export default ModalComponentMap;
