import React, { useState , useEffect} from 'react';
import styled from 'styled-components';
import DropdownMenu from '../assets/styledcomponents/DropdownMenu.js';
import DateInput from '../assets/styledcomponents/DateInput.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddUnitComponent = ({ selectedBuilding, setPostSuccess, setAddingUnit, addingUnit }) => {
    
    const [editingRowId, setEditingRowId] = useState(null);
    const [formUnits, setFormUnits] = useState({
        _id: "",
        buildingcollection: "",
        building_name: "",
        address: "",                
        date_created: "",
        unit_name: "",
        model_type:"",
        floor_number:0,
        monthly_rent:0,
        square_feet:0,
        number_bedrooms:0,
        number_baths:0,
        availability:true,
        available_from:"",
        floorplan_url: '',
    });
    const [origUnits, setOrigUnits] = useState({
        _id: "",
        buildingcollection: "",
        building_name: "",
        address: "",                
        date_created: "",
        unit_name: "",
        model_type:"",
        floor_number:0,
        monthly_rent:0,
        square_feet:0,
        number_bedrooms:0,
        number_baths:0,
        availability:true,
        available_from:"",
        floorplan_url: '',
    });
    
    const handleSave = (event, id) => {
        event.preventDefault();
      
        fetch(`${API_BASE_URL}/api/update-unit`, {
            credentials: 'include',
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "formData": formUnits,
                }),
        })
            .then(response => {
                console.log("response.ok", response)
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            }).then(data => {
                setOrigUnits(formUnits)
                setEditingRowId(null);
                setPostSuccess(true)
            })
            .catch(error => console.log(error));
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setFormUnits(origUnits)
        setEditingRowId(null);
        setAddingUnit(!addingUnit)
    };

    const handleInputChange = (event,  field, value) => {
        if (field!=="available_from"){
            event.preventDefault();
        }
        // console.log("event, id, field, value",event, id, field, value)        
        const newData = {...formUnits, [field]: value };        
        setFormUnits(newData)
    };

 ///////////////////////////////////////////////////////////////////////

 const handleAddUnit = (event, id) => {
        
    event.preventDefault();
   
    fetch(`${API_BASE_URL}/api/add-unit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "formData": formUnits,
                "selectedBuilding":selectedBuilding,
            }),
        credentials: 'include'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                alert('request');
            }
        })
        .then((response_j) => {
            console.log('Unit added successfully',response_j.data.insertedId);
            // setShowModal(false);
            setPostSuccess(true)
            // setSelectedBuilding(null)
            // navigate("/admin")
            setAddingUnit(!addingUnit)
        })
        .catch((error) => console.log(error));

};

///////////////////////////////////////////////////////////////
    return (
        <Item key={formUnits._id}>
        {/* <Value_id>{count}</Value_id> */}
    
        <Value
            type="text"
            value={formUnits.unit_name}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "unit_name", event.target.value)}
        />
    
        <Value
            type="text"
            value={formUnits.model_type}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "model_type", event.target.value)}
        />
    
        <Value
            type="number"
            value={formUnits.floor_number}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "floor_number", event.target.value)}
        />
        <Value
            type="number"
            value={formUnits.monthly_rent}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "monthly_rent", event.target.value)}
        />
        <Value
            type="number"
            value={formUnits.square_feet}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "square_feet", event.target.value)}
        />
    
        <Value
            type="number"
            value={formUnits.number_bedrooms}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "number_bedrooms", event.target.value)}
        />
    
        <Value
            type="number"
            value={formUnits.number_baths}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "number_baths", event.target.value)}
        />
        
        <div style={{minWidth:"111px", display: "flex"}}>                                                                
            <DropdownMenu
                options={[{label: "Vacant",value: true},{label: "Occupied",value: false}]}
                onSelect={(event, optionValue) =>handleInputChange(event, "availability", optionValue)}
                value={formUnits.availability}
                // isDisabled={editingRowId !== formUnits._id}                                                           
            />                                                                
        </div>
                                                                    
        <div style={{minWidth:"100px", display: "flex"}}>        
            <DateInput
                selectedDate={formUnits.available_from}
                // handleInputChangeEdit={handleInputChange}
                handleInputChangeEdit={(optionValue) =>handleInputChange("", "available_from", optionValue)}
                itemId={formUnits._id}
                field={'available_from'}
                // disabled={editingRowId !==formUnits._id}
            />
        </div>
    
        <Value
            type="text"
            value={formUnits.floorplan_url}
            // disabled={editingRowId !== formUnits._id}
            onChange={(event) => handleInputChange(event, "floorplan_url", event.target.value)}
        />
    
        <Label>            
            <>
                <Button type="submit" onClick={(event) => handleAddUnit(event, formUnits._id)}>Save</Button>
                {/* <Button type="submit" disabled={loadingButton}>
                    {loadingButton ? (<LoadingStyle style={{width: '20px',height: '20px'}}/>) : (<>Save</>)}
                </Button> */}

                <Button onClick={handleCancel}>Cancel</Button>
                {/* <Button onClick={(event) => handleDeleteUnit(event, item._id,item.buildingcollection)}>Delete</Button> */}
            </>                
        </Label>
    
    
    </Item>
    );
};

export default AddUnitComponent;

const Item = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
background-color: #f5f5f5;
padding: 10px;
border-bottom: 1px solid black;
`;

const Button = styled.button`
font-size:22px;
padding-left: 10px;
padding-right: 10px;
background-color: #ef5350;
color: #fff;
border: none;
border-radius: 5px;
margin-bottom: 1px;
cursor: pointer;
&:hover {
background-color: #e53935;
}
`;

const Label = styled.div`
width: 100px;
font-weight: bold;
flex: 1;
margin-right: 10px;
/* padding: 0 12px; */
font-family: var(--font-body);
min-width: 100px;
`;

const Value = styled.input`
width: 100px;
flex: 1;
margin-right: 10px;
/* border:none; */
`;
