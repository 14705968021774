import React from "react";
import styled from "styled-components";
import { AiFillCheckCircle } from "react-icons/ai";
import { ImSpinner } from "react-icons/im";


const RadioWrapper = styled.div`
    margin: 5px;
    display: inline-block;
    vertical-align: middle;
`;

const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 1em; /* Use em to scale with the wrapper */
    margin-right: 0.5em; /* Use em for relative spacing */
    user-select: none;
`;

const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ span:after {
        display: block;
    }
`;

const RadioCheckmark = styled.span`
    position: relative;
    height: 1em; /* Scales with the font size */
    width: 1em; /* Scales with the font size */
    border-radius: 50%;
    border: 2px solid #333;
    margin-right: 0.3em;

    &:after {
        content: "";
        position: absolute;
        display: none;
        top: 20%;
        left: 20%;
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: #333;
    }
`;

export const RadioButton = ({ label, style, ...props }) => (
    <RadioWrapper style={style}>
        <RadioLabel>
            <RadioInput type="radio" {...props} />
            <RadioCheckmark />
            {label}
        </RadioLabel>
    </RadioWrapper>
);

const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SuccessIcon = styled.i`
  font-size: 36px;
  color: green;
`;

const SuccessMessage = styled.p`
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: green;
`;

export const Success = ({ message }) => (
  <SuccessWrapper>
    <SuccessIcon><AiFillCheckCircle/></SuccessIcon>
    <SuccessMessage>{message}</SuccessMessage>
  </SuccessWrapper>
);

export const LoadingStyle = styled(ImSpinner)` 
    /* width: ${props => props.size || '50px'}; */
    /* height: ${props => props.size || '50px'}; */
    width: 50px;
    height: 50px;
    object-fit: cover;
    color:black;
    border-radius: 50%;
    animation: rotation 2s infinite linear;
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;

