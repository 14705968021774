import { apiClient } from "./ApiClient";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function getRegionsHomePage() {
    return apiClient.get(`${API_BASE_URL}/api/regions?numberOfRegions=5`);
}

export function getRegionByLocation(location) {
    return apiClient.get(`${API_BASE_URL}/api/regions/${location}`);
}