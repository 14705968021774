// Import statements
import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { CurrentUserContext } from "../security/CurrentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { RadioButton, Success, LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";
import ModalComponentMap from "./ModalComponentMap";
import { NavLink } from "react-router-dom";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { getBuildings } from "../api/RentalApiService";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "rc-slider/assets/index.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import SidebarListItem from "./SidebarListItem.jsx";
// import MarkerClusterGroup from "react-leaflet-markercluster";
// import "react-leaflet-markercluster/dist/styles.min.css";
// import { MarkerClusterGroup } from "react-leaflet-cluster";
import MarkerClusterGroup from "react-leaflet-cluster";
// import 'leaflet.markercluster/dist/MarkerCluster.css';
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
    // zIndexOffset: 500 // Higher z-index
});

const defaultIcon = new L.Icon.Default();

const redIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41], // width, height in pixels (adjust as needed)
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
    shadowSize: [41, 41], // size of the shadow
    className: "marker-icon-red"
    // zIndexOffset: 2000 // Higher z-index
});

const greenIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "marker-icon-green"
    // zIndexOffset: 2000 // Higher z-index
});

const yellowIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "marker-icon-yellow"
    // zIndexOffset: 2000 // Higher z-index
});

export default function ProspectingMap() {
    const {
        currentUserState,
        actions: { receiveUserInfoFromServer, receiveUserAuthFromServer, removeCurrentUser }
    } = useContext(CurrentUserContext);

    const [filters, setFilters] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const [loadingState, setLoadingState] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const navigate = useNavigate();

    const [units, setUnits] = useState([]);
    const [origUnits, setOrigUnits] = useState([]);

    const { select_list, unit_id } = useParams();

    const [selectedList, setSelectedList] = useState(null);
    const [lists, setLists] = useState([]);

    const [postSuccess, setPostSuccess] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [editingRowId, setEditingRowId] = useState(null);

    // State variables for statuses and notes
    const [unitStatuses, setUnitStatuses] = useState({});
    const [unitNotes, setUnitNotes] = useState({});

    // State variable for view mode
    const [viewMode, setViewMode] = useState("map"); // 'map' or 'list'
    const [isSatelliteView, setIsSatelliteView] = useState(false);

    const [unitsKey, setUnitsKey] = useState(0);

    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const mapRef = useRef(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMarkerClick = (unitId) => {
        setSelectedUnitId(unitId);
        // Navigate to include the unit ID in the path
        // navigate(`/admin/prospectingmap/${select_list}/${unitId}`);
        window.history.pushState(
            {}, // State object
            '', // Title (not used by most browsers)
            `/admin/prospectingmap/${select_list}/${unitId}` // New URL
        );
    };

    useEffect(() => {
        // If there is a unit_id in the URL, set the selectedUnitId
        if (unit_id) {
            setSelectedUnitId(unit_id);
        }
    }, [unit_id]);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (selectedUnitId && mapRef.current && !mapRef.current.contains(event.target)) {
    //             setSelectedUnitId(null);
    //             navigate(`/admin/prospectingmap/${select_list}`);
    //         }
    //     };
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [selectedUnitId, navigate, select_list]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Function to handle saving notes
    const handleSaveNotes = (unitId, propertyColl, newNotes) => {
        // Update the global unitNotes state
        setUnitNotes((prevNotes) => ({
            ...prevNotes,
            [unitId]: newNotes
        }));

        setUnits((prevUnits) => prevUnits.map((unit) => (unit._id === unitId ? { ...unit, notes: newNotes } : unit)));

        // Proceed to save to the server
        fetch(`${API_BASE_URL}/api/edit-prospecting-list`, {
            credentials: "include",
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                propertyId: unitId,
                updates: { notes: newNotes },
                propertyColl: propertyColl
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            })
            .then((data) => {
                // Update the units array with the updated notes
                alert("Notes updated successfully.");
            })
            .catch((error) => console.log(error));
    };

    // Function to handle saving status
    const handleSaveStatus = (unitId, propertyColl, newStatus) => {
        unitId = unitId.toString();

        // Update the global unitStatuses state
        setUnitStatuses((prevStatuses) => ({
            ...prevStatuses,
            [unitId]: newStatus
        }));

        setUnits((prevUnits) => prevUnits.map((unit) => (unit._id.toString() === unitId ? { ...unit, propstatus: newStatus } : unit)));

        // Proceed to save to the server
        fetch(`${API_BASE_URL}/api/edit-prospecting-list`, {
            credentials: "include",
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                propertyId: unitId,
                updates: { propstatus: newStatus },
                propertyColl: propertyColl
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            })
            .then((data) => {
                // const cachedData = localStorage.getItem(`prospectingList_${selectedList}`);
                // if (cachedData) {
                // const unitsData = JSON.parse(cachedData);
                // const updatedUnitsData = unitsData.map((unit) => (unit._id.toString() === unitId ? { ...unit, propstatus: newStatus } : unit));
                // localStorage.setItem(`prospectingList_${selectedList}`, JSON.stringify(updatedUnitsData));
                // localStorage.setItem(`prospectingList_${selectedList}_timestamp`, Date.now());
                // }
                alert("Status updated successfully.");
            })
            .catch((error) => console.log(error));
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Get icon based on status
    const getIconForStatus = (status) => {
        if (status === "not interested") return redIcon;
        if (status === "interested") return greenIcon;
        if (status === "call back") return yellowIcon;
        if (status === "none") return defaultIcon;
        return defaultIcon;
    };

    const handleLogout = () => {
        console.log("logout");

        fetch(`${API_BASE_URL}/api/logout`, {
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    removeCurrentUser();
                    navigate("/");
                } else {
                    throw new Error("Logout failed");
                }
            })
            .catch((error) => console.log(error));
    };

    const toggleEditing = (event, id) => {
        event.preventDefault();
        setPostSuccess(false);
        if (editingRowId === id) {
            setEditingRowId(null);
        } else {
            setEditingRowId(id);
            setUnits(origUnits);
        }
    };

    const handleListSelect = (listcollection) => {
        navigate("/admin/prospectingmap/" + listcollection);
        // setSelectedList(listcollection);
    };

    // Fetch all buildings
    useEffect(() => {
        fetch(`${API_BASE_URL}/api/get-all-prosp-lists`, {
            credentials: "include",
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert("cannot get buildings");
                }
            })
            .then((response_j) => {
                setLists(response_j.data);
                setLoadingState(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [postSuccess]);

    // Fetch individual list, with details
    useEffect(() => {
        // setPostSuccess(false);

        // setSelectedList(select_list);

        setUnits([]);
        setOrigUnits([]);
        setFilters(null);
        setSelectedFilters(null);
        setUnitStatuses({});
        setUnitNotes({});

        if (select_list) {
            // const cachedData = localStorage.getItem(`prospectingList_${selectedList}`);
            // const cacheTimestamp = localStorage.getItem(`prospectingList_${selectedList}_timestamp`);
            // if (cachedData && cacheTimestamp && Date.now() - cacheTimestamp < 3600000) {
            // const unitsData = JSON.parse(cachedData);
            // setUnits(unitsData);
            // setLoadingState(false);
            // setOrigUnits(unitsData);

            // Initialize filters and statuses
            // initializeFiltersAndStatuses(unitsData)
            // } else {
            setLoadingState(true);
            fetch(`${API_BASE_URL}/api/get-prospecting-list/` + select_list, {
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        alert("cannot get units in building selected");
                    }
                })
                .then((response_j) => {
                    const unitsData = response_j.data;
                    setUnits(unitsData);
                    setOrigUnits(unitsData);
                    // setUnits(response_j.data);
                    // setOrigUnits(response_j.data);
                    setLoadingState(false);

                    initializeFiltersAndStatuses(unitsData);

                    // localStorage.setItem(`prospectingList_${selectedList}`, JSON.stringify(unitsData));
                    // localStorage.setItem(`prospectingList_${selectedList}_timestamp`, Date.now());
                    setUnitsKey((prevKey) => prevKey + 1);
                })
                .catch((error) => {
                    console.log(error);
                });
            // }
        }
    }, [select_list]);

    const initializeFiltersAndStatuses = (unitsData) => {
        // Compute min and max values
        const newFilters = {
            building_size: { min: Infinity, max: -Infinity },
            land_size: { min: Infinity, max: -Infinity },
            building_valuation: { min: Infinity, max: -Infinity },
            year_built: { min: Infinity, max: -Infinity },
            num_non_res_units: { min: Infinity, max: -Infinity }
        };

        unitsData.forEach((unit) => {
            const bs = parseFloat(unit.building_size);
            const ls = parseFloat(unit.land_size);
            const bv = parseFloat(unit.building_valuation);
            const yb = parseFloat(unit.year_built);
            const nnru = parseFloat(unit.num_non_res_units);

            if (!isNaN(bs)) {
                newFilters.building_size.min = Math.min(newFilters.building_size.min, bs);
                newFilters.building_size.max = Math.max(newFilters.building_size.max, bs);
            }

            if (!isNaN(ls)) {
                newFilters.land_size.min = Math.min(newFilters.land_size.min, ls);
                newFilters.land_size.max = Math.max(newFilters.land_size.max, ls);
            }

            if (!isNaN(bv)) {
                newFilters.building_valuation.min = Math.min(newFilters.building_valuation.min, bv);
                newFilters.building_valuation.max = Math.max(newFilters.building_valuation.max, bv);
            }

            if (!isNaN(yb)) {
                newFilters.year_built.min = Math.min(newFilters.year_built.min, yb);
                newFilters.year_built.max = Math.max(newFilters.year_built.max, yb);
            }

            if (!isNaN(nnru)) {
                newFilters.num_non_res_units.min = Math.min(newFilters.num_non_res_units.min, nnru);
                newFilters.num_non_res_units.max = Math.max(newFilters.num_non_res_units.max, nnru);
            }
        });

        setFilters(newFilters);
        setSelectedFilters({ ...newFilters }); // Initialize selected filters to full range

        //////////////////////////////////////////////////////////////////

        // Initialize unitStatuses and unitNotes
        const initialStatuses = {};
        const initialNotes = {};
        unitsData.forEach((unit) => {
            const unitIdStr = unit._id.toString();
            initialStatuses[unitIdStr] = unit.propstatus ? unit.propstatus.toLowerCase() : "";
            initialNotes[unitIdStr] = unit.notes || "";
        });
        setUnitStatuses(initialStatuses);
        setUnitNotes(initialNotes);
    };

    const handleDeleteUnit = (event, id, buildingCollection) => {
        event.preventDefault();
        fetch(`${API_BASE_URL}/api/delete-unit`, {
            method: "DELETE",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                unitId: id,
                buildingCollection: buildingCollection
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            })
            .then((data) => {
                setEditingRowId(null);
                setPostSuccess(true);
            })
            .catch((error) => console.log(error));
    };

    const handleSave = (event, id) => {
        event.preventDefault();
        const formData = units.find((unit) => {
            return id === unit._id;
        });

        fetch(`${API_BASE_URL}/api/update-unit`, {
            credentials: "include",
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                formData: formData
            })
        })
            .then((response) => {
                console.log("response.ok", response);
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            })
            .then((data) => {
                setOrigUnits(units);
                setEditingRowId(null);
                setPostSuccess(true);
            })
            .catch((error) => console.log(error));
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setUnits(origUnits);
        setEditingRowId(null);
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleAddList = (submitBuilding, list_name) => {
        console.log("submitBuilding", submitBuilding);
        setPostSuccess(false);
        const requestData = {
            excelData: submitBuilding,
            list_name: list_name
        };

        fetch(`${API_BASE_URL}/api/createlist-excel`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
            credentials: "include"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert(response.message);
                }
            })
            .then((response_j) => {
                console.log("Building added successfully");
                setShowModal(false);
                setPostSuccess(true);
                setSelectedList(null);
                navigate("/admin/prospectingmap");
            })
            .catch((error) => console.log(error));
    };

    function FitBounds({ units }) {
        const map = useMap();

        useEffect(() => {
            if (units.length === 0) return;

            if (selectedUnitId) {
                // If a single unit is selected, center on that unit
                const selectedUnit = units.find((u) => u._id.toString() === selectedUnitId.toString());
                if (selectedUnit) {
                    const latLng = L.latLng(selectedUnit.latitude, selectedUnit.longitude);
                    map.setView(latLng, 18); // Zoom level can be adjusted
                }
            } else {
                // Otherwise fit all units in bounds
                const bounds = L.latLngBounds(units.map((unit) => [unit.latitude, unit.longitude]));
                map.fitBounds(bounds);
            }
        }, [units, map, selectedUnitId]);

        return null;
    }
    // const filteredUnits = units.filter((unit) => {
    //     const bs = parseFloat(unit.building_size);
    //     const ls = parseFloat(unit.land_size);
    //     const bv = parseFloat(unit.building_valuation);
    //     const yb = parseFloat(unit.year_built);
    //     const nnru = parseFloat(unit.num_non_res_units);

    //     const bsFilter = !isNaN(bs) && bs >= selectedFilters.building_size.min && bs <= selectedFilters.building_size.max;
    //     const lsFilter = !isNaN(ls) && ls >= selectedFilters.land_size.min && ls <= selectedFilters.land_size.max;
    //     const bvFilter = !isNaN(bv) && bv >= selectedFilters.building_valuation.min && bv <= selectedFilters.building_valuation.max;
    //     const ybFilter = !isNaN(yb) && yb >= selectedFilters.year_built.min && yb <= selectedFilters.year_built.max;
    //     const nnruFilter = !isNaN(nnru) && nnru >= selectedFilters.num_non_res_units.min && nnru <= selectedFilters.num_non_res_units.max;

    //     const status = unitStatuses[unit._id]?.toLowerCase() || 'none';
    //     const statusFilter = selectedStatuses.length === 0 || selectedStatuses.includes(status);

    //     return bsFilter && lsFilter && bvFilter && ybFilter && nnruFilter && statusFilter;
    // });

    const filteredUnits = useMemo(() => {
        return units.filter((unit) => {
            const bs = parseFloat(unit.building_size);
            const ls = parseFloat(unit.land_size);
            const bv = parseFloat(unit.building_valuation);
            const yb = parseFloat(unit.year_built);
            const nnru = parseFloat(unit.num_non_res_units);

            const bsFilter = !isNaN(bs) && bs >= selectedFilters.building_size.min && bs <= selectedFilters.building_size.max;
            const lsFilter = !isNaN(ls) && ls >= selectedFilters.land_size.min && ls <= selectedFilters.land_size.max;
            const bvFilter = !isNaN(bv) && bv >= selectedFilters.building_valuation.min && bv <= selectedFilters.building_valuation.max;
            const ybFilter = !isNaN(yb) && yb >= selectedFilters.year_built.min && yb <= selectedFilters.year_built.max;
            const nnruFilter = !isNaN(nnru) && nnru >= selectedFilters.num_non_res_units.min && nnru <= selectedFilters.num_non_res_units.max;

            const status = unitStatuses[unit._id]?.toLowerCase() || "none";
            const statusFilter = selectedStatuses.length === 0 || selectedStatuses.includes(status);

            return bsFilter && lsFilter && bvFilter && ybFilter && nnruFilter && statusFilter;
        });
    }, [units, selectedFilters, selectedStatuses, unitStatuses]);

    return (
        <>
            <Layout>
                <NavBar>
                    <ToggleButton onClick={toggleSidebar} show={sidebarVisible}>
                        {sidebarVisible ? "Hide" : "Show"}
                    </ToggleButton>

                    {/* <ToggleButtonMapList onClick={() => setViewMode(viewMode === 'map' ? 'list' : 'map')} show={sidebarVisible}>
                        {viewMode === 'map' ? 'View as List' : 'View as Map'}
                    </ToggleButtonMapList> */}

                    <ToggleMapViewButton onClick={() => setIsSatelliteView(!isSatelliteView)} show={sidebarVisible}>
                        {isSatelliteView ? "Map (Show Base)" : "Map (Show Satellite)"}
                    </ToggleMapViewButton>

                    <section className="w3l-header-4">
                        <div
                            className="header"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                background: "#b0bcbf"
                            }}
                        >
                            <LogoContainer>
                                <LogoLink to="/">
                                    <LogoImage src="/logo.png" alt="BROWEN Logo" />
                                </LogoLink>
                            </LogoContainer>
                            {/* <div
                                className="header"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    background: "#b0bcbf"
                                }}
                            > */}
                            <MenuButton onClick={handleMenuToggle}>&#9776; {/* Hamburger icon */}</MenuButton>

                            <NavItemsContainer isMenuOpen={isMenuOpen}>
                                <NavItems to="/admin" className="signin">
                                    Admin
                                </NavItems>
                                <NavItems to="/admin/prospectingmap" className="signin">
                                    Prospecting
                                </NavItems>
                                <NavItems to="/admin/instantmessage" className="signin">
                                    Messaging
                                </NavItems>
                                <NavItems onClick={handleLogout} className="signin">
                                    Logout
                                </NavItems>
                            </NavItemsContainer>
                            {/* </div> */}
                        </div>
                    </section>
                </NavBar>
                <SideBarStyled show={sidebarVisible}>
                    <Sidebar
                        buildings={lists}
                        onBuildingSelect={handleListSelect}
                        setShowModal={setShowModal}
                        onLoadingState={loadingState}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        selectedStatuses={selectedStatuses}
                        setSelectedStatuses={setSelectedStatuses}
                        selectedList={select_list}
                    />
                </SideBarStyled>

                <Content sidebarVisible={sidebarVisible}>
                    <div ref={mapRef} style={{ flex: 1, padding: "20px", overflowY: "auto" }}>
                        {viewMode === "map" ? (
                            <MapWrapper key={unitsKey}>
                                <MapContainer key={unitsKey} maxZoom={18} scrollWheelZoom={true} zoomControl={true}>
                                    {isSatelliteView ? (
                                        <TileLayer
                                            attribution="Tiles &copy; Esri ..."
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    ) : (
                                        <TileLayer
                                            attribution='&copy; <a href="https://carto.com/">CARTO</a>'
                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        />
                                    )}
                                    <FitBounds units={filteredUnits} />
                                    <MarkerClusterGroup
                                        chunkedLoading={true}
                                        maxClusterRadius={30}
                                        disableClusteringAtZoom={19}
                                        spiderfyOnMaxZoom={true}
                                        spiderfyDistanceMultiplier={1.5}
                                        showCoverageOnHover={false}
                                    >
                                        {filteredUnits.map((unit) => {
                                            const unitIdStr = unit._id.toString();
                                            const status = unitStatuses[unit._id]?.toLowerCase() || "";
                                            const icon = getIconForStatus(status);
                                            const markerKey = `${unitIdStr}_${unitsKey}`; // Ensure unique key

                                            return (
                                                <Marker
                                                    key={unitIdStr}
                                                    // key={markerKey}
                                                    position={[unit.latitude, unit.longitude]}
                                                    icon={icon}
                                                    eventHandlers={{
                                                        click: () => handleMarkerClick(unitIdStr)
                                                    }}
                                                    
                                                >
                                                    
                                                    <Popup minWidth={300}>
                                                        <PopupContent
                                                            unitId={unitIdStr}
                                                            collectionName={unit.listcollection}
                                                            status={status}
                                                            notes={unitNotes[unitIdStr] || ""}
                                                            onSaveNotes={(newNotes) => handleSaveNotes(unitIdStr, unit.listcollection, newNotes)}
                                                            onSaveStatus={(newStatus) => handleSaveStatus(unitIdStr, unit.listcollection, newStatus)}
                                                        />
                                                    </Popup>
                                                </Marker>
                                            );
                                        })}
                                    </MarkerClusterGroup>
                                </MapContainer>
                                <ModalComponentMap
                                    isOpen={showModal}
                                    onSubmit={handleAddList}
                                    setShowModal={setShowModal}
                                    setPostSuccess={setPostSuccess}
                                    setSelectedBuilding={setSelectedList}
                                />
                            </MapWrapper>
                        ) : (
                            <ListView
                                units={filteredUnits}
                                unitStatuses={unitStatuses}
                                unitNotes={unitNotes}
                                handleSaveNotes={handleSaveNotes}
                                handleSaveStatus={handleSaveStatus}
                            />
                        )}
                    </div>
                </Content>
            </Layout>
        </>
    );
}

const MenuButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 768px) {
        flex-direction: column;
        display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
        background-color: #b0bcbf;
        position: absolute;
        top: 55px;
        right: 0;
        width: 150px;
        z-index: 9999;
    }
`;

const UnitItem = ({ unit, status, notes, onSaveNotes, onSaveStatus }) => {
    const [activeTab, setActiveTab] = useState("property info");
    const [localNotes, setLocalNotes] = useState(notes);
    const [localStatus, setLocalStatus] = useState(status);

    useEffect(() => {
        setLocalNotes(notes);
        setLocalStatus(status);
    }, [notes, status]);

    return (
        <div>
            <strong>{unit.property_address}</strong>
            <TabHeader>
                <TabButton onClick={() => setActiveTab("property info")} active={activeTab === "property info"}>
                    Property Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("owner info")} active={activeTab === "owner info"}>
                    Owner Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("notes")} active={activeTab === "notes"}>
                    Notes
                </TabButton>
                <TabButton onClick={() => setActiveTab("alert")} active={activeTab === "alert"}>
                    Alert
                </TabButton>
            </TabHeader>
            <TabContent>
                {activeTab === "property info" && (
                    <div>
                        property_address: {unit.property_address}
                        <br />
                        lot_number: {unit.lot_number}
                        <br />
                        {/* Other property info */}
                    </div>
                )}
                {activeTab === "owner info" && (
                    <div>
                        owner_eval: {unit.owner_eval}
                        <br />
                        {/* Other owner info */}
                    </div>
                )}
                {activeTab === "notes" && (
                    <div>
                        <TextArea value={localNotes} onChange={(e) => setLocalNotes(e.target.value)} placeholder="Add notes here" />
                        <SaveButton onClick={() => onSaveNotes(localNotes)}>Save</SaveButton>
                    </div>
                )}
                {activeTab === "alert" && (
                    <div>
                        <RadioButton
                            label="Not Interested"
                            value="not interested"
                            checked={localStatus === "not interested"}
                            onChange={() => setLocalStatus("not interested")}
                        />
                        <RadioButton
                            label="Interested"
                            value="interested"
                            checked={localStatus === "interested"}
                            onChange={() => setLocalStatus("interested")}
                        />
                        <RadioButton
                            label="Call Back"
                            value="call back"
                            checked={localStatus === "call back"}
                            onChange={() => setLocalStatus("call back")}
                        />
                        <RadioButton label="none" value="none" checked={localStatus === "none"} onChange={() => setLocalStatus("none")} />
                        <SaveButton onClick={() => onSaveStatus(localStatus)}>Save</SaveButton>
                    </div>
                )}
            </TabContent>
        </div>
    );
};

const ListView = ({ units, unitStatuses, unitNotes, handleSaveNotes, handleSaveStatus }) => {
    return (
        <div>
            {units.map((unit) => {
                const status = unitStatuses[unit._id]?.toLowerCase() || "";
                const notes = unitNotes[unit._id] || "";
                return (
                    <div key={unit._id} style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "10px" }}>
                        <UnitItem
                            unit={unit}
                            status={status}
                            notes={notes}
                            onSaveNotes={(newNotes) => handleSaveNotes(unit._id, unit.listcollection, newNotes)}
                            onSaveStatus={(newStatus) => handleSaveStatus(unit._id, unit.listcollection, newStatus)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

// PopupContent Component with Tabs
const PopupContent = ({ unitId, collectionName, status, notes, onSaveNotes, onSaveStatus }) => {
    unitId = unitId.toString();
    const [activeTab, setActiveTab] = useState("property info");
    const [localNotes, setLocalNotes] = useState(notes);
    const [localStatus, setLocalStatus] = useState(status);
    const [fullUnitData, setFullUnitData] = useState(null);

    // const [unitDataCache, setUnitDataCache] = useState({});

    // Update local state when props change
    useEffect(() => {
        setLocalNotes(notes);
        setLocalStatus(status);
    }, [notes, status]);

    // Fetch full unit data when the component mounts
    useEffect(() => {
        const fetchUnitData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/get-unit-by-id?unitId=${unitId}&collectionName=${collectionName}`, {
                    credentials: "include",
                    headers: { "Content-Type": "application/json" }
                });

                if (response.ok) {
                    const data = await response.json();
                    setFullUnitData(data.data);
                } else {
                    console.error("Failed to fetch unit data");
                }
            } catch (error) {
                console.error("Error fetching unit data:", error);
            }
        };

        fetchUnitData();
    }, [unitId, collectionName]);

    useEffect(() => {
        if (fullUnitData) {
            setLocalNotes(fullUnitData.notes || "");
            setLocalStatus(fullUnitData.propstatus ? fullUnitData.propstatus.toLowerCase() : "");
        }
    }, [fullUnitData]);

    // Show loading indicator while data is being fetched
    if (!fullUnitData) {
        return (
            <LoadingStyle
                style={{
                    width: "80px",
                    height: "80px",
                    margin: "20px"
                }}
            />
        );
    }

    const BoldLabel = ({ label, value }) => (
        <>
            <span style={{ fontWeight: "bold" }}>{label}:</span> {value}
            <br />
        </>
    );

    return (
        <div>
            <TabHeader>
                <TabButton onClick={() => setActiveTab("property info")} active={activeTab === "property info"}>
                    Property Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("owner info")} active={activeTab === "owner info"}>
                    Owner Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("notes")} active={activeTab === "notes"}>
                    Notes
                </TabButton>
                <TabButton onClick={() => setActiveTab("alert")} active={activeTab === "alert"}>
                    Alert
                </TabButton>
            </TabHeader>
            <TabContent>
                {activeTab === "property info" && (
                    <div>
                        <BoldLabel label="Property Address" value={fullUnitData.property_address} />
                        <BoldLabel label="Lot Number" value={fullUnitData.lot_number} />
                        <BoldLabel label="Building Size" value={fullUnitData.building_size} />
                        <BoldLabel label="Building Valuation" value={fullUnitData.building_valuation} />
                        <BoldLabel label="Land Size" value={fullUnitData.land_size} />
                        <BoldLabel label="Property Use" value={fullUnitData.property_use} />
                        <BoldLabel label="Year Built" value={fullUnitData.year_built} />
                        <BoldLabel label="Number of Units" value={fullUnitData.num_non_res_units} />
                    </div>
                )}
                {activeTab === "owner info" && (
                    <div>
                        <BoldLabel label="Owner Eval" value={fullUnitData.owner_eval} />
                        <BoldLabel label="Owner Address" value={fullUnitData.owner_address} />
                        <BoldLabel label="Cidreq Owner 1" value={fullUnitData.cidreq_owner1} />
                        <BoldLabel label="Phone Number 1" />
                        <BoldLabel label="Cidreq Address 1" value={fullUnitData.cidreq_address1} />
                        <BoldLabel label="Cidreq Owner 2" value={fullUnitData.cidreq_owner2} />
                        <BoldLabel label="Phone Number 2" />
                        <BoldLabel label="Cidreq Address 2" value={fullUnitData.cidreq_address2} />
                    </div>
                )}
                {activeTab === "notes" && (
                    <div>
                        <TextArea value={localNotes} onChange={(e) => setLocalNotes(e.target.value)} placeholder="Add notes here" />
                        <SaveButton onClick={() => onSaveNotes(localNotes)}>Save</SaveButton>
                    </div>
                )}
                {/* // Alert Tab (Status) */}
                {activeTab === "alert" && (
                    <div>
                        <RadioButton
                            label="Not Interested"
                            value="not interested"
                            checked={localStatus === "not interested"}
                            onChange={() => setLocalStatus("not interested")}
                        />
                        <RadioButton
                            label="Interested"
                            value="interested"
                            checked={localStatus === "interested"}
                            onChange={() => setLocalStatus("interested")}
                        />
                        <RadioButton
                            label="Call Back"
                            value="call back"
                            checked={localStatus === "call back"}
                            onChange={() => setLocalStatus("call back")}
                        />
                        <RadioButton label="none" value="none" checked={localStatus === "none"} onChange={() => setLocalStatus("none")} />
                        <SaveButton onClick={() => onSaveStatus(localStatus)}>Save</SaveButton>
                    </div>
                )}
            </TabContent>
        </div>
    );
};
const SaveButton = styled.button`
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #45a049;
    }
`;

// Styled Components for Tabs and Content
const TabHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #ccc;
`;

const TabButton = styled.button`
    flex: 1;
    padding: 8px;
    background: ${(props) => (props.active ? "#fff" : "#eee")};
    border: none;
    border-bottom: ${(props) => (props.active ? "2px solid blue" : "none")};
    cursor: pointer;

    &:hover {
        background: #ddd;
    }
`;

const TabContent = styled.div`
    padding: 8px;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
`;

// Sidebar Component
const Sidebar = ({
    buildings,
    onBuildingSelect,
    setShowModal,
    onLoadingState,
    filters,
    selectedFilters,
    setSelectedFilters,
    selectedStatuses,
    setSelectedStatuses,
    selectedList
}) => {
    const handleStatusChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedStatuses(selectedOptions);
    };
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

    const handleStatusCheckboxChange = (event, statusValue) => {
        if (event.target.checked) {
            setSelectedStatuses((prev) => [...prev, statusValue]);
        } else {
            setSelectedStatuses((prev) => prev.filter((status) => status !== statusValue));
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownOpen && !event.target.closest("#status-filter-dropdown") && !event.target.closest("#status-filter-button")) {
                setStatusDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [statusDropdownOpen]);

    return (
        <>
            {/* <h2 style={{ marginBottom: "10px" }}>Lists</h2> */}
            <>
                {onLoadingState ? (
                    <LoadingStyle
                        style={{
                            width: "80px",
                            height: "80px",
                            margin: "20px"
                        }}
                    />
                ) : (
                    <>
                        <SidebarListItem buildings={buildings} onBuildingSelect={onBuildingSelect} selectedList={selectedList} />
                        {/* <ul>
                            
                            <li key={"all_lists_together"} style={styles.sidebarItem}>
                                <button onClick={() => onBuildingSelect("all_lists_together")} style={styles.button}>
                                    All Lists
                                </button>
                            </li>
                            {buildings.map((building) => (
                                <li key={building._id} style={styles.sidebarItem}>
                                    <button onClick={() => onBuildingSelect(building.listcollection)} style={styles.button}>
                                        {building.list_name}
                                    </button>
                                </li>
                            ))}
                        </ul> */}
                        <button onClick={() => setShowModal(true)} style={styles.addButton}>
                            Add New List
                        </button>
                    </>
                )}
            </>
            {/* Filter Sliders */}
            <div>
                <h3>Filters</h3>
                {filters && (
                    <>
                        {/* filter status */}
                        <div style={{ position: "relative", marginBottom: "10px", marginTop: "10px" }}>
                            {/* <StatusFilterButton onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}>Status Filter</StatusFilterButton> */}
                            <StatusFilterButton id="status-filter-button" onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}>
                                Status Filter
                            </StatusFilterButton>

                            {statusDropdownOpen && (
                                <DropdownMenu id="status-filter-dropdown">
                                    {["Interested", "Not Interested", "Call Back", "None"].map((statusOption) => (
                                        <DropdownItem key={statusOption}>
                                            <Checkbox
                                                hidden
                                                type="checkbox"
                                                value={statusOption.toLowerCase()}
                                                checked={selectedStatuses.includes(statusOption.toLowerCase())}
                                                onChange={(e) => handleStatusCheckboxChange(e, statusOption.toLowerCase())}
                                            />
                                            <RadioButton
                                                // iconSize={"20px"}
                                                style={{ transform: "scale(1)" }}
                                                value={statusOption.toLowerCase()}
                                                checked={selectedStatuses.includes(statusOption.toLowerCase())}
                                            />
                                            {statusOption}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            )}
                        </div>

                        {/* Building Size Slider */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Building Size: <br />
                                {Math.round(selectedFilters.building_size.min).toLocaleString()} -{" "}
                                {Math.round(selectedFilters.building_size.max).toLocaleString()}
                            </label>
                            <CustomSlider
                                range
                                min={filters.building_size.min}
                                max={filters.building_size.max}
                                value={[selectedFilters.building_size.min, selectedFilters.building_size.max]}
                                onChange={(values) => setSelectedFilters((prev) => ({ ...prev, building_size: { min: values[0], max: values[1] } }))}
                            />
                        </div>
                        {/* Repeat for other filters */}
                        {/* Land Size Slider */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Land Size: <br />
                                {Math.round(selectedFilters.land_size.min).toLocaleString()} -{" "}
                                {Math.round(selectedFilters.land_size.max).toLocaleString()}
                            </label>
                            <CustomSlider
                                range
                                min={filters.land_size.min}
                                max={filters.land_size.max}
                                value={[selectedFilters.land_size.min, selectedFilters.land_size.max]}
                                onChange={(values) => setSelectedFilters((prev) => ({ ...prev, land_size: { min: values[0], max: values[1] } }))}
                            />
                        </div>
                        {/* Building Valuation Slider */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Building Valuation: <br />
                                {Math.round(selectedFilters.building_valuation.min).toLocaleString()} -{" "}
                                {Math.round(selectedFilters.building_valuation.max).toLocaleString()}
                            </label>
                            <CustomSlider
                                range
                                min={filters.building_valuation.min}
                                max={filters.building_valuation.max}
                                value={[selectedFilters.building_valuation.min, selectedFilters.building_valuation.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({ ...prev, building_valuation: { min: values[0], max: values[1] } }))
                                }
                            />
                        </div>
                        {/* Year Built Slider */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Year Built: <br />
                                {selectedFilters.year_built.min} - {selectedFilters.year_built.max}
                            </label>
                            <CustomSlider
                                range
                                min={filters.year_built.min}
                                max={filters.year_built.max}
                                value={[selectedFilters.year_built.min, selectedFilters.year_built.max]}
                                onChange={(values) => setSelectedFilters((prev) => ({ ...prev, year_built: { min: values[0], max: values[1] } }))}
                            />
                        </div>
                        {/* Number of Non-Residential Units Slider */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Non-Residential Units: <br />
                                {selectedFilters.num_non_res_units.min} - {selectedFilters.num_non_res_units.max}
                            </label>
                            <CustomSlider
                                range
                                min={filters.num_non_res_units.min}
                                max={filters.num_non_res_units.max}
                                value={[selectedFilters.num_non_res_units.min, selectedFilters.num_non_res_units.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({ ...prev, num_non_res_units: { min: values[0], max: values[1] } }))
                                }
                            />
                        </div>
                        {/* Reset Filters Button */}
                        <Button
                            style={{ position: "relative", marginBottom: "20px" }}
                            onClick={() => {
                                setSelectedFilters({ ...filters });
                                setSelectedStatuses([]);
                            }}
                        >
                            Reset Filters
                        </Button>
                        {/* <button onClick={() => setSelectedFilters({ ...filters })}>Reset Filters</button> */}
                    </>
                )}
            </div>
        </>
    );
};
const ToggleMapViewButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "275px" : "75px")};
    top: 65px;
    /* z-index: 200; */

    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;

    transition: left 0.3s ease-in-out;

    &:hover {
        background-color: #e53935;
    }
`;

const ListItem = styled.li`
    padding: 12px 16px;
    background-color: ${(props) => (props.isSelected ? "#e6f7ff" : "#fff")};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isSelected ? "#cceeff" : "#f5f5f5")};
    }

    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
`;

const CustomSlider = styled(Slider)`
    .rc-slider-track {
        background-color: #abe2fb !important;
    }

    .rc-slider-handle {
        border-color: #abe2fb !important;
        box-shadow: none !important;
    }
`;

const StatusFilterButton = styled.button`
    width: 100%;
    padding: 10px;
    background: #abe2fb;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
    text-align: left;
    position: relative;
`;

const DropdownMenu = styled.div`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 120%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.label`
    display: block;
    padding: 8px;
    cursor: pointer;
    color: black;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: grey;
    }
`;

const Checkbox = styled.input`
    margin-right: 8px;
`;

// Styled Components
const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NavBar = styled.div`
    text-align: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #b0bcbf;
    padding: 10px 20px;
`;

const SideBarStyled = styled.div`
    background-color: #b0bcbf;
    color: white;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 61px; /* Adjust based on the height of your NavBar */
    left: 0;
    bottom: 0;
    transition: transform 0.3s ease-in-out;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
`;

const Content = styled.div`
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? "200px" : "0")};
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 60px); /* Adjust based on the height of your NavBar */
    transition: margin-left 0.3s ease-in-out;
`;

const ToggleButtonMapList = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "490px" : "285px")};
    top: 65px;
    z-index: 1010;

    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;

    transition: left 0.3s ease-in-out;

    &:hover {
        background-color: #e53935;
    }
`;

const ToggleButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "200px" : "0")};
    top: 65px;
    z-index: 1010;

    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;

    transition: left 0.3s ease-in-out;

    &:hover {
        background-color: #e53935;
    }
`;

const MapWrapper = styled.div`
    width: 100%;
    height: 82vh;

    margin-top: 20px;

    .leaflet-container {
        width: 100%;
        height: 100%;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoImage = styled.img`
    width: 200px;
    height: auto;

    @media (max-width: 580px) {
        width: 170px;
    }

    @media (max-width: 480px) {
        width: 125px;
    }
`;

const styles = {
    sidebar: {
        width: "250px",
        background: "#f4f4f4",
        padding: "20px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)"
    },
    sidebarItem: {
        marginBottom: "10px"
    },
    button: {
        width: "100%",
        padding: "10px",
        border: "none",
        borderRadius: "5px",
        color: "black",
        cursor: "pointer"
    },
    addButton: {
        width: "100%",
        padding: "10px",
        marginTop: "10px",
        background: "#4CAF50",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        // color: "#fff",
        borderRadius: "5px",
        marginBottom: "10px"
    }
};

const NavItems = styled(NavLink)`
    text-decoration: none;
    transition: 100ms;
    color: white;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    margin: 5px;
    font-size: 20px;
    width: 125px;
    text-align: center;
    border-radius: 3px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid transparent;
        color: grey;
    }

    &:active {
        color: white;
        border: 3px solid transparent;
        background-color: var(--primary-color);
    }
    .active {
        color: var(--primary-color);
    }
    &:disabled,
    &:hover:disabled {
        background-color: #707070;
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 10px;
    border-bottom: 1px solid black;
`;

const Button = styled.button`
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    &:hover {
        background-color: #e53935;
    }
`;

const Label = styled.div`
    display: flex;
    align-items: center; /* Optional: centers items vertically */
    width: 100px;
    font-weight: bold;
    flex: 1;
    margin-right: 10px;
    /* padding: 0 12px; */
    font-family: var(--font-body);
    min-width: 100px;
`;

const Value = styled.input`
    width: 100px;
    flex: 1;
    margin-right: 10px;
    /* border:none; */
`;
