import { useState, useEffect, useContext } from 'react';
import { RadioButton, Success, LoadingStyle } from '../assets/styledcomponents/StyledComponents.js';
import styled from "styled-components";
import Papa from 'papaparse';
import { Link, useNavigate } from 'react-router-dom';
import DropdownPropType from '../assets/styledcomponents/DropdownPropType.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ModalComponent = ({ isOpen, onSubmit, setShowModal, setPostSuccess, setSelectedBuilding}) => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        units: '',
        proptype:"",
    });
    
    const [loadingState, setLoadingState] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleDropDown = (event, field, value) => {        
        event.preventDefault();
        // console.log("event",event, field, value)        
        const newData = {...formData, [field]: value };        
        setFormData(newData)
        // console.log("newData",newData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingState(true)
        onSubmit(formData);
        setFormData({name: '',address: '',units: '', proptype:""})
        setLoadingState(false)
    };

    if (!isOpen) return null;

    const handleFileRead = (event) => {
        const file = event.target.files[0];
        if (file) {
          Papa.parse(file, {
            complete: function(results) {
                setExcelData(results.data);

                // console.log("excelData",excelData)
        
                // do fetch here to server:        
                fetch(`${API_BASE_URL}/api/createbuilding-excel`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({excelData:results.data}),
                    credentials: 'include'
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            alert(response.message);
                        }
                    })
                    .then((response_j) => {
                        console.log('Building added successfully');
                        setShowModal(false);
                        setPostSuccess(true)
                        setSelectedBuilding(null)
                        navigate("/admin")
                    })
                    .catch((error) => console.log(error));
            },
            header: true,
            skipEmptyLines: true,
          });
        }
      };
      const handleCancel = (event) => {
        event.preventDefault();
        setFormData({name: '',address: '',units: '',proptype:"",})
        setShowModal(false)
      };
    

      const handleDownloadTemplate = () => {
		const templateData = [[
            "building_name",
            "address",
            "proptype",
            "unit_name",
            "model_type",
            "floor_number",
            "monthly_rent",
            "square_feet",
            "number_bedrooms",
            "number_baths",
            "availability",
            "available_from",
            "floorplan_url"
        ]// Replace with exact column headers needed
		];

		const csv = Papa.unparse(templateData);
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);

		link.setAttribute("href", url);
		link.setAttribute("download", "building_template.csv");
		link.style.visibility = "hidden";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modal}>
                <h2 style={{margin:"10px"}}>Add New Building</h2>
                
                <form onSubmit={handleSubmit}>
                <GridContainer>
                {/* <StyledInput placeholder="Input 1" />
                <StyledInput placeholder="Input 2" />
                <StyledInput placeholder="Input 3" />
                <StyledInput placeholder="Input 4" /> */}
    
                    <label style={{margin:"5px"}}>
                        Building Name:
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </label>
                    <label style={{margin:"5px"}}>
                        Address:
                        <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                    </label>
                    <label style={{margin:"5px"}}>
                        Number of Units:
                        <input type="number" name="units" value={formData.units} onChange={handleChange} required />
                    </label>

                    <div style={{minWidth:"111px", display: "flex",margin:"5px"}}>
                    <label>
                        Property Type:
                    </label>
                            <DropdownPropType
                                options={[
                                    {label: "rent",value: "rent"},
                                    {label: "sale",value: "sale"},
                                    {label: "house",value: "house"},
                                    {label: "commercial",value: "commercial"},
                                ]}
                                onSelect={(event, optionValue) =>handleDropDown(event, "proptype", optionValue)}
                                value={formData.proptype}
                                isDisabled={false}                                                           
                            />                                                                
                    </div>                        
                    
                    
                   
                    {loadingState ? (
                            <LoadingStyle
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    margin: '5px'
                                }}
                            />
                        ) : (
                            <>
                    
                        {/* <div style={{ display: "flex",  flexDirection: "column"}}> */}
                        <Button type="submit">Add Building</Button>
                        <Button type="button" onClick={(event)=>handleCancel(event)}>Cancel</Button>

                        {/* <Button style={{}} type="button" onClick={handleFileRead}>Add Building with CSV</Button> */}
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileRead}
                            style={{ display: 'none' }}
                            id="fileInput"
                        />
                        <Button onClick={() => document.getElementById('fileInput').click()}>
                            Upload CSV
                        </Button>

                        <Button style={{    
                            // whiteSpace:"normal",
                            // wordWrap:"break-word",
                            // width: "130px"
                            // fontSize: 10
                        }} onClick={handleDownloadTemplate}>Download CSV Template</Button>

                        {/* create loading state for this  */}
                      
                        </>)}
                    
                    </GridContainer>
                </form>
                
            </div>
        </div>
    );
};

// Styled div container with grid layout
const GridContainer = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 1fr); // This line creates two columns
  gap: 10px; // Space between grid items
  padding: 20px; // Padding around the grid container

`;

// Styled input element
const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
font-size:22px;
padding-left: 10px;
padding-right: 10px;
background-color: #ef5350;
color: #fff;
border: none;
border-radius: 5px;
margin-bottom: 1px;
margin-right: 5px;
cursor: pointer;
&:hover {
background-color: #e53935;
}
`;

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Add this line
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',        
    }
};



export default ModalComponent;